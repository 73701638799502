import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackUpStatus } from './models/back-up-status';
import { KlantStatusInfo } from './models/klant-status-info';
import { TaskRemark } from './models/task-remark';
import { VoipStatus } from './models/voip-status';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  //baseUrl: string = 'http://localhost:14612/api';
  baseUrl: string = 'https://api.monitor.autotaal.biz/api';
  klantStatusOverview: KlantStatusInfo[];

  constructor(private http: HttpClient) {}

  getKlantStatusOverview(): Observable<KlantStatusInfo[]> {
    return this.http.get<KlantStatusInfo[]>(
      this.baseUrl + '/monitor/klant-status-info'
    );
  }

  getBackUpOverview(): Observable<BackUpStatus[]> {
    return this.http.get<BackUpStatus[]>(
      this.baseUrl + '/monitor/backup-status'
    );
  }

  storeTaskRemark(taskRemark: TaskRemark): Observable<Object> {
    return this.http.post(this.baseUrl + '/monitor/task-remark', taskRemark);
  }

  getVoipStatus(): Observable<VoipStatus> {
    //return this.http.get<VoipStatus>(this.baseUrl + '/voip/status-test');
    return this.http.get<VoipStatus>(this.baseUrl + '/voip/status');
  }
}
