<div class="container-fluid mt-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="card mt-3">
          <div class="card-header monitor-header">
            Wachtrij
          </div>
          <div class="card-body">
            <div *ngIf="status">
              <div *ngIf="status.callers.length === 0" class="text-large">Er is momenteel geen wachtrij.</div>
              <div *ngFor="let caller of status.callers" class="text-large">
                <fa-icon [icon]="phone" class="text-primary"></fa-icon> {{caller.callerNumber}} {{caller.callerName}}
                ({{getDuration(caller.startTime)}})
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card mt-3">
          <div class="card-header monitor-header">
            Gesprekken
          </div>
          <div class="card-body">
            <div *ngIf="status">
              <div *ngIf="status.connections.length === 0" class="text-large">Er zijn momenteel geen gesprekken</div>
              <div *ngFor="let connection of status.connections" class="text-large">
                <fa-icon [icon]="connected" class="text-success"></fa-icon> {{connection.callerNumber}}
                {{connection.callerName}} ->
                {{connection.phoneNumber}} {{connection.phoneName}}
                ({{getDuration(connection.startTime)}})
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div *ngIf="hasError()" class="alert alert-danger mt-3" role="alert">
        {{status.statusMessage}}
      </div>
    </div>
  </div>
</div>