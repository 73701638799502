import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { KlantStatusInfo } from '../models/klant-status-info';
import { TaskRemark } from '../models/task-remark';

@Component({
  selector: 'app-remark-dialog',
  templateUrl: './remark-dialog.component.html',
  styleUrls: ['./remark-dialog.component.css'],
})
export class RemarkDialogComponent implements OnInit {
  visible: boolean = false;
  klant: KlantStatusInfo;
  taakId: number;
  taakOmschrijving: string;
  heeftOpmerking: boolean;
  opmerking: string;
  aantalDagen: string = '1';

  hasError: boolean = false;
  errorMessage: string;

  //public onSave: Subject<TaskRemark>;

  @Output() saved = new EventEmitter<TaskRemark>();

  constructor(private backendService: BackendService) {}

  ngOnInit(): void {
    this.klant = new KlantStatusInfo();
    this.klant.klantNaam = '-';
  }

  showDialog(
    klant: KlantStatusInfo,
    taakId: number,
    heeftOpmerking: boolean
  ): void {
    this.klant = klant;
    this.setTaak(taakId);
    this.heeftOpmerking = heeftOpmerking;

    if (heeftOpmerking) {
      this.opmerking = this.getTaakBericht(taakId);
      //this.opmerking = "test";
      console.log(this.opmerking);
    } else {
      this.opmerking = '';
    }
    this.visible = true;
  }

  closeDialog(): void {
    //console.log(this.klant);
    this.visible = false;
  }

  saveDialog(): void {
    const remark = new TaskRemark();
    remark.customerId = this.klant.klantId;
    remark.duration = parseInt(this.aantalDagen);
    remark.remark = this.opmerking;
    remark.taskId = this.taakId;

    //this.saved.emit(remark);
    console.log(remark);

    this.backendService.storeTaskRemark(remark).subscribe(
      (result) => {
        this.closeDialog();
        this.saved.emit(remark);
      },
      (error) => {
        this.errorMessage = "Fout bij het opslaan!";
        this.hasError = true;
      }
    );
  }

  setTaak(taakId: number) {
    this.taakId = taakId;
    this.taakOmschrijving = this.getTaakOmschrijving(taakId);
    console.log(taakId);
  }

  getTaakOmschrijving(taakId: number): string {
    switch (taakId) {
      case 1001:
        return 'HD ruimte';
      case 1003:
        return 'Mijngrossier proces';
      case 1004:
        return 'SQL Agent service';
      case 1005:
        return 'Easy-trade versie';
      case 1006:
        return 'Mail Alerter service';
      case 1007:
        return 'Bandenmodule';
      case 1008:
        return 'Dashboard';
      case 1009:
        return 'Intersprint aanmaken';
      case 1010:
        return 'Intersprint voorraden';
      case 1011:
        return 'Intertyre';
      case 1013:
        return 'VanDenBan volledig';
      case 1014:
        return 'VanDenBan bijwerken';
      case 1020:
        return 'Interne NAS backup';
      case 1021:
        return 'Macrium backup naar NAS';
      case 1022:
        return 'USB backup';
      default:
        return '(onbekend)';
    }
  }

  getTaakBericht(taakId: number): string {
    switch (taakId) {
      case 1001:
        return this.klant.schijfruimteBericht;
      case 1003:
        return this.klant.mijngrossierProcesBericht;
      case 1004:
        return this.klant.sqlServerAgentBericht;
      case 1006:
        return this.klant.mailAlerterBericht;
      case 1007:
        return this.klant.bandenBericht;
      case 1008:
        return this.klant.dashboardBericht;
      case 1009:
        return this.klant.intersprintAanmakenPrijzenBericht;
      case 1010:
        return this.klant.intersprintVoorradenPrijzenBericht;
      case 1011:
        return this.klant.intertyreAanmakenPrijzenBericht;
      case 1013:
        return this.klant.vanDenBanVolledigBericht;
      case 1014:
        return this.klant.vanDenBanBijwerkenBericht;
      case 1020:
        return this.klant.backupNasInternBericht;
      case 1021:
        return this.klant.backupNasMacriumBericht;
      case 1022:
        return this.klant.backupMacriumUsbBericht;
      default:
        return '';
    }
  }
}
