import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthResponse } from '../models/auth-response';
import { BackendService } from '../backend.service';
import { KlantStatusInfo } from '../models/klant-status-info';
import { RemarkDialogComponent } from '../remark-dialog/remark-dialog.component';
import { TaskRemark } from '../models/task-remark';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.css'],
})
export class MonitorComponent implements OnInit {
  klanten: KlantStatusInfo[];
  isBusy: boolean = true;
  showHeader: boolean = false;
  interval: number = 30000;
  hasError: boolean = false;
  errorMessage: string = '';
  testV2: boolean = true;

  @ViewChild('remarkDialog') remarkDialog: RemarkDialogComponent;

  constructor(
    private backendService: BackendService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // const headerParam = this.route.snapshot.queryParamMap.get("showheader");
    // console.log(`showHeader = ${headerParam}`);
    // if (headerParam == "0") this.showHeader = false;

    this.getKlantStatusOverview();
  }

  getKlantStatusOverview() {
    this.backendService.getKlantStatusOverview().subscribe(
      (view) => {
        this.klanten = view;
        console.log(`Fetched ${this.klanten.length} items`);
        console.log(this.klanten);
        this.isBusy = false;

        setTimeout(() => {
          this.getKlantStatusOverview();
        }, this.interval);
      },
      (error) => {
        console.log(error);
        this.hasError = true;
        if (error.status == 401) {
          const authResponse = error.error as AuthResponse;
          this.errorMessage = `Geen toegang (${authResponse.message})`;
        } else {
          this.errorMessage = 'Fout bij het ophalen van de gegevens.';
        }
      }
    );
  }

  getWindowsUpdateColor(updateCount: number): string {
    if (updateCount < 0) return '';
    if (updateCount < 5) {
      return 'groen';
    } else {
      return 'rood';
    }
  }

  getResultColor(result: number): string {
    switch (result) {
      case 0:
        return 'rood';
      case 1:
        return 'groen';
      case -20:
        return 'geel';
      default:
        return '';
    }
  }

  getNasInternColor(result: number): string {
    if (result == -10) return '';
    if (result == -20) return 'geel';
    if (result == -1) return 'rood';
    if (result >= 0 && result <= 3) return 'groen';
    if (result > 3) {
      return 'rood';
    }
  }

  showDialog(klant: KlantStatusInfo, taakId: number, heeftOpmerking: boolean) {
    this.remarkDialog.showDialog(klant, taakId, heeftOpmerking);
  }

  saveRemark(remark: TaskRemark) {
    console.log(remark);
  }
}
