import { Component, OnInit } from '@angular/core';
import { BackendService } from '../backend.service';
import { VoipStatus } from '../models/voip-status';

import {
  faPhoneSquare,
  faHeadphones,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-voip-monitor',
  templateUrl: './voip-monitor.component.html',
  styleUrls: ['./voip-monitor.component.css'],
})
export class VoipMonitorComponent implements OnInit {
  status: VoipStatus;
  interval: number = 1000;

  phone = faPhoneSquare;
  connected = faHeadphones;

  constructor(private backendService: BackendService) {}

  ngOnInit(): void {
    this.getVoipStatus();
  }

  hasError() {
    return this.status && this.status.statusCode == -100;
  }

  getVoipStatus() {
    this.backendService.getVoipStatus().subscribe(
      (status) => {
        this.status = status;

        setTimeout(() => {
          this.getVoipStatus();
        }, this.interval);
      },
      (error) => {
        console.log(error);

        setTimeout(() => {
          this.getVoipStatus();
        }, this.interval);
      }
    );
  }

  getDuration(startTime: string) {
    const now = new Date();
    let duration = now.valueOf() - new Date(startTime).valueOf();
    if (duration < 0) duration = 0;
    return this.getTimeFromDuration(duration);
  }

  getTimeFromDuration(duration: number): string {
    var seconds = Math.floor((duration / 1000) % 60);
    var minutes = Math.floor((duration / (1000 * 60)) % 60);
    var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    var time = '';
    if (hours > 0) {
      time = time + (hours < 10 ? '0' + hours : hours) + ':';
    }
    time = time + (minutes < 10 ? '0' + minutes : minutes) + ':';
    time = time + (seconds < 10 ? '0' + seconds : seconds);

    return time;
  }
}

// getKlantStatusOverview() {
//   this.backendService.getKlantStatusOverview().subscribe(
//     (view) => {
//       this.klanten = view;
//       console.log(`Fetched ${this.klanten.length} items`);
//       console.log(this.klanten);
//       this.isBusy = false;

//       setTimeout(() => {
//         this.getKlantStatusOverview();
//       }, this.interval);
//     },
//     (error) => {
//       console.log(error);
//       this.hasError = true;
//       if (error.status == 401) {
//         const authResponse = error.error as AuthResponse;
//         this.errorMessage = `Geen toegang (${authResponse.message})`;
//       } else {
//         this.errorMessage = 'Fout bij het ophalen van de gegevens.';
//       }
//     }
//   );
// }
