<div class="header hidden-mobile" *ngIf="showHeader && !hasError">
  <div class="title text-nowrap">
    Autotaal Monitor
    <app-clock class=""></app-clock>
  </div>
</div>

<div class="fullheight blue" *ngIf="isBusy && !hasError">
  <div class="subtitle">
    Bezig met het ophalen van de gegevens...
  </div>
</div>

<div *ngIf="testV2 && !isBusy && !hasError" class="d-flex flex-column full-height">

  <div style="flex-grow: 1;" class="d-flex flex-row">

    <div class="mr-auto box-cell" style="width: 100%;min-width: 250px;">
      Grossier
    </div>
    <div class="box-cell box-width text-center" title="Windows Update Agent">
      WUA
    </div>
    <div class="box-cell box-width text-center" title="Hardeschijf ruimte">
      HDD
    </div>
    <div class="box-cell box-width text-center" title="SQL Server Agent">
      SQL
    </div>
    <div class="box-cell box-width text-center" title="SQL Server Agent">
      VER
    </div>
    <div class="box-cell box-width text-center" title="Mail Alerter">
      Alerter
    </div>
    <div class="box-cell box-width text-center" title="Bandenmodule">
      Banden
    </div>
    <div class="box-cell box-width-2 text-center" title="Intersprint prijzen (L) en voorraden (R)">
      Intersprint
    </div>
    <div class="box-cell box-width text-center" title="Intertyre velgen">
      Intertyre
    </div>
    <div class="box-cell box-width-2 text-center" title="Van den ban volledig (L) en bijwerken (R)">
      Van den Ban
    </div>
    <div class="box-cell box-width text-center" title="Mijngrossier proces">
      MG
    </div>
    <div class="box-cell box-width-2 text-center" title="Macrium, NAS Intern + USB">
      <a class="link-white" routerLink="/backups">NAS</a>
    </div>
    <div class="box-cell box-width text-center" title="USB backup">
      <a class="link-white" routerLink="/backups">USB</a>
    </div>
  </div>

  <div *ngFor="let klant of klanten" style="flex-grow: 1;" class="d-flex flex-row">

      <div class="mr-auto box-cell" style="width: 100%;min-width: 250px;">
        {{klant.klantNaam}}
      </div>

      <div class="box-cell box-width text-center" [ngClass]="getWindowsUpdateColor(klant.windowsUpdatesOK)" title="{{klant.windowsUpdatesBericht}}">
        <span class="fout" *ngIf="klant.windowsUpdatesOK > 0">{{klant.windowsUpdatesOK}}</span>
      </div>

      <div class="box-cell box-width text-center" [ngClass]="getResultColor(klant.schijfruimteOK)" title="{{klant.schijfruimteBericht}}">
        <span class="fout" *ngIf="klant.schijfruimteOK == 0" (click)="showDialog(klant, 1001, false)">ERR</span>
        <span class="fout" *ngIf="klant.schijfruimteOK == -20" (click)="showDialog(klant, 1001, true)">MSG</span>
      </div>

      <div class="box-cell box-width text-center" [ngClass]="getResultColor(klant.sqlServerAgentOK)" title="{{klant.sqlServerAgentBericht}}">
        <span class="fout" *ngIf="klant.sqlServerAgentOK == 0" (click)="showDialog(klant, 1004, false)">ERR</span>
        <span class="fout" *ngIf="klant.sqlServerAgentOK == -20" (click)="showDialog(klant, 1004, true)">MSG</span>
      </div>

      <div class="box-cell box-width text-center">
        {{klant.etVersie}}
      </div>

      <div class="box-cell box-width text-center" [ngClass]="getResultColor(klant.mailAlerterOK)" title="{{klant.mailAlerterBericht}}">
        <span class="fout" *ngIf="klant.mailAlerterOK == 0" (click)="showDialog(klant, 1006, false)">ERR</span>
        <span class="fout" *ngIf="klant.mailAlerterOK == -20" (click)="showDialog(klant, 1006, true)">MSG</span>
      </div>

      <div class="box-cell box-width text-center" [ngClass]="getResultColor(klant.bandenOK)" title="{{klant.bandenBericht}}">
        <span class="fout" *ngIf="klant.bandenOK == 0" (click)="showDialog(klant, 1007, false)">ERR</span>
        <span class="fout" *ngIf="klant.bandenOK == -20" (click)="showDialog(klant, 1007, true)">MSG</span>
      </div>

      <div class="box-cell box-width text-center" [ngClass]="getResultColor(klant.intersprintAanmakenPrijzenOK)" title="{{klant.intersprintAanmakenPrijzenBericht}}">
        <span class="fout" *ngIf="klant.intersprintAanmakenPrijzenOK == 0"
          (click)="showDialog(klant, 1009, false)">ERR</span>
        <span class="fout" *ngIf="klant.intersprintAanmakenPrijzenOK == -20"
          (click)="showDialog(klant, 1009, true)">MSG</span>
      </div>

      <div class="box-cell box-width text-center" [ngClass]="getResultColor(klant.intersprintVoorradenPrijzenOK)" title="{{klant.intersprintVoorradenPrijzenBericht}}">
        <span class="fout" *ngIf="klant.intersprintVoorradenPrijzenOK == 0"
          (click)="showDialog(klant, 1010, false)">ERR</span>
        <span class="fout" *ngIf="klant.intersprintVoorradenPrijzenOK == -20"
          (click)="showDialog(klant, 1010, true)">MSG</span>
      </div>

      <div class="box-cell box-width text-center" [ngClass]="getResultColor(klant.intertyreAanmakenPrijzenOK)" title="{{klant.intertyreAanmakenPrijzenBericht}}">
        <span class="fout" *ngIf="klant.intertyreAanmakenPrijzenOK == 0"
          (click)="showDialog(klant, 1011, false)">ERR</span>
        <span class="fout" *ngIf="klant.intertyreAanmakenPrijzenOK == -20"
          (click)="showDialog(klant, 1011, true)">MSG</span>
      </div>

      <div class="box-cell box-width text-center" [ngClass]="getResultColor(klant.vanDenBanVolledigOK)" title="{{klant.vanDenBanVolledigBericht}}">
        <span class="fout" *ngIf="klant.vanDenBanVolledigOK == 0" (click)="showDialog(klant, 1013, false)">ERR</span>
        <span class="fout" *ngIf="klant.vanDenBanVolledigOK == -20" (click)="showDialog(klant, 1013, true)">MSG</span>
      </div>

      <div class="box-cell box-width text-center" [ngClass]="getResultColor(klant.vanDenBanBijwerkenOK)" title="{{klant.vanDenBanBijwerkenBericht}}">
        <span class="fout" *ngIf="klant.vanDenBanBijwerkenOK == 0" (click)="showDialog(klant, 1014, false)">ERR</span>
        <span class="fout" *ngIf="klant.vanDenBanBijwerkenOK == -20" (click)="showDialog(klant, 1014, true)">MSG</span>
      </div>

      <div class="box-cell box-width text-center" [ngClass]="getResultColor(klant.mijngrossierProcesOK)" title="{{klant.mijngrossierProcesBericht}}">
        <span class="fout" *ngIf="klant.mijngrossierProcesOK == 0" (click)="showDialog(klant, 1003, false)">ERR</span>
        <span class="fout" *ngIf="klant.mijngrossierProcesOK == -20" (click)="showDialog(klant, 1003, true)">MSG</span>
      </div>

      <div class="box-cell box-width text-center" [ngClass]="getResultColor(klant.backupNasMacriumOK)" title="{{klant.backupNasMacriumBericht}}">
        <span class="fout" *ngIf="klant.backupNasMacriumOK == 0" (click)="showDialog(klant, 1021, false)">ERR</span>
        <span class="fout" *ngIf="klant.backupNasMacriumOK == -20" (click)="showDialog(klant, 1021, true)">MSG</span>
      </div>

      <div class="box-cell box-width text-center" [ngClass]="getNasInternColor(klant.backupNasInternOK)" title="{{klant.backupNasInternBericht}}">
        <span class="fout" *ngIf="klant.backupNasInternOK == -1" (click)="showDialog(klant, 1020, false)">ERR</span>
        <span class="fout" *ngIf="klant.backupNasInternOK == -20" (click)="showDialog(klant, 1020, true)">MSG</span>
        <span class="fout" *ngIf="klant.backupNasInternOK > 0"
          (click)="showDialog(klant, 1020, false)">{{klant.backupNasInternOK}}</span>
      </div>

      <div class="box-cell box-width text-center" [ngClass]="getNasInternColor(klant.backupMacriumUsbOK)" title="{{klant.backupMacriumUsbBericht}}">
        <span class="fout" *ngIf="klant.backupMacriumUsbOK == -1" (click)="showDialog(klant, 1022, false)">ERR</span>
        <span class="fout" *ngIf="klant.backupMacriumUsbOK == -20" (click)="showDialog(klant, 1022, true)">MSG</span>
        <span class="fout" *ngIf="klant.backupMacriumUsbOK > 0"
          (click)="showDialog(klant, 1022, false)">{{klant.backupMacriumUsbOK}}</span>
      </div>
  </div>

</div>

<div *ngIf="!testV2" class="full-height table-responsive">
  <table class="table lijst" *ngIf="!isBusy && !hasError" [ngClass]="{nopadding: !showHeader}">
    <tr>
      <th class="links">
        Grossier
      </th>
      <th title="Windows Update Agent">
        WUA
      </th>
      <th title="Hardeschijf ruimte">
        HDD
      </th>
      <th title="SQL Server Agent">
        SQL
      </th>
      <th class="boxcolumn">
        Versie
      </th>
      <th title="Mail Alerter">
        Alerter
      </th>
      <th title="Bandenmodule">
        Banden
      </th>
      <th title="Intersprint prijzen (L) en voorraden (R)" colspan="2">
        Intersprint (P &amp; V)
      </th>
      <th title="Intertyre velgen">
        Intertyre
      </th>
      <th title="Van den ban volledig (L) en bijwerken (R)" colspan="2">
        Van den ban
      </th>
      <!--<th title="Dashboard logging">
          Dash
      </th>-->
      <th title="Mijngrossier proces">
        MG
      </th>
      <th title="Macrium, NAS Intern + USB" colspan="2">
        <a class="link-white" routerLink="/backups">Backup NAS</a>
      </th>
      <th title="USB backup" colspan="1">
        <a class="link-white" routerLink="/backups">USB</a>
      </th>
    </tr>

    <tr *ngFor="let klant of klanten">

      <td class="links">
        {{klant.klantNaam}}
      </td>

      <td title="{{klant.windowsUpdatesBericht}}" [ngClass]="getWindowsUpdateColor(klant.windowsUpdatesOK)"
        class="boxcolumn">
        <span class="fout" *ngIf="klant.windowsUpdatesOK > 0">{{klant.windowsUpdatesOK}}</span>
      </td>

      <td [ngClass]="getResultColor(klant.schijfruimteOK)" title="{{klant.schijfruimteBericht}}" class="boxcolumn">
        <span class="fout" *ngIf="klant.schijfruimteOK == 0" (click)="showDialog(klant, 1001, false)">ERR</span>
        <span class="fout" *ngIf="klant.schijfruimteOK == -20" (click)="showDialog(klant, 1001, true)">MSG</span>
      </td>

      <td [ngClass]="getResultColor(klant.sqlServerAgentOK)" title="{{klant.sqlServerAgentBericht}}" class="boxcolumn">
        <span class="fout" *ngIf="klant.sqlServerAgentOK == 0" (click)="showDialog(klant, 1004, false)">ERR</span>
        <span class="fout" *ngIf="klant.sqlServerAgentOK == -20" (click)="showDialog(klant, 1004, true)">MSG</span>
      </td>

      <td class="midden boxcolumn">
        {{klant.etVersie}}
      </td>

      <td [ngClass]="getResultColor(klant.mailAlerterOK)" title="{{klant.mailAlerterBericht}}" class="boxcolumn">
        <span class="fout" *ngIf="klant.mailAlerterOK == 0" (click)="showDialog(klant, 1006, false)">ERR</span>
        <span class="fout" *ngIf="klant.mailAlerterOK == -20" (click)="showDialog(klant, 1006, true)">MSG</span>
      </td>

      <td [ngClass]="getResultColor(klant.bandenOK)" title="{{klant.bandenBericht}}" class="boxcolumn">
        <span class="fout" *ngIf="klant.bandenOK == 0" (click)="showDialog(klant, 1007, false)">ERR</span>
        <span class="fout" *ngIf="klant.bandenOK == -20" (click)="showDialog(klant, 1007, true)">MSG</span>
      </td>

      <td [ngClass]="getResultColor(klant.intersprintAanmakenPrijzenOK)"
        title="{{klant.intersprintAanmakenPrijzenBericht}}" class="boxcolumn">
        <span class="fout" *ngIf="klant.intersprintAanmakenPrijzenOK == 0"
          (click)="showDialog(klant, 1009, false)">ERR</span>
        <span class="fout" *ngIf="klant.intersprintAanmakenPrijzenOK == -20"
          (click)="showDialog(klant, 1009, true)">MSG</span>
      </td>

      <td [ngClass]="getResultColor(klant.intersprintVoorradenPrijzenOK)"
        title="{{klant.intersprintVoorradenPrijzenBericht}}" class="boxcolumn">
        <span class="fout" *ngIf="klant.intersprintVoorradenPrijzenOK == 0"
          (click)="showDialog(klant, 1010, false)">ERR</span>
        <span class="fout" *ngIf="klant.intersprintVoorradenPrijzenOK == -20"
          (click)="showDialog(klant, 1010, true)">MSG</span>
      </td>

      <td [ngClass]="getResultColor(klant.intertyreAanmakenPrijzenOK)" title="{{klant.intertyreAanmakenPrijzenBericht}}"
        class="boxcolumn">
        <span class="fout" *ngIf="klant.intertyreAanmakenPrijzenOK == 0"
          (click)="showDialog(klant, 1011, false)">ERR</span>
        <span class="fout" *ngIf="klant.intertyreAanmakenPrijzenOK == -20"
          (click)="showDialog(klant, 1011, true)">MSG</span>
      </td>

      <td [ngClass]="getResultColor(klant.vanDenBanVolledigOK)" title="{{klant.vanDenBanVolledigBericht}}"
        class="boxcolumn">
        <span class="fout" *ngIf="klant.vanDenBanVolledigOK == 0" (click)="showDialog(klant, 1013, false)">ERR</span>
        <span class="fout" *ngIf="klant.vanDenBanVolledigOK == -20" (click)="showDialog(klant, 1013, true)">MSG</span>
      </td>

      <td [ngClass]="getResultColor(klant.vanDenBanBijwerkenOK)" title="{{klant.vanDenBanBijwerkenBericht}}"
        class="boxcolumn">
        <span class="fout" *ngIf="klant.vanDenBanBijwerkenOK == 0" (click)="showDialog(klant, 1014, false)">ERR</span>
        <span class="fout" *ngIf="klant.vanDenBanBijwerkenOK == -20" (click)="showDialog(klant, 1014, true)">MSG</span>
      </td>

      <td [ngClass]="getResultColor(klant.mijngrossierProcesOK)" title="{{klant.mijngrossierProcesBericht}}"
        class="boxcolumn">
        <span class="fout" *ngIf="klant.mijngrossierProcesOK == 0" (click)="showDialog(klant, 1003, false)">ERR</span>
        <span class="fout" *ngIf="klant.mijngrossierProcesOK == -20" (click)="showDialog(klant, 1003, true)">MSG</span>
      </td>

      <td [ngClass]="getResultColor(klant.backupNasMacriumOK)" title="{{klant.backupNasMacriumBericht}}"
        class="boxcolumn">
        <span class="fout" *ngIf="klant.backupNasMacriumOK == 0" (click)="showDialog(klant, 1021, false)">ERR</span>
        <span class="fout" *ngIf="klant.backupNasMacriumOK == -20" (click)="showDialog(klant, 1021, true)">MSG</span>
      </td>

      <td [ngClass]="getNasInternColor(klant.backupNasInternOK)" title="{{klant.backupNasInternBericht}}"
        class="boxcolumn">
        <span class="fout" *ngIf="klant.backupNasInternOK == -1" (click)="showDialog(klant, 1020, false)">ERR</span>
        <span class="fout" *ngIf="klant.backupNasInternOK == -20" (click)="showDialog(klant, 1020, true)">MSG</span>
        <span class="fout" *ngIf="klant.backupNasInternOK > 0"
          (click)="showDialog(klant, 1020, false)">{{klant.backupNasInternOK}}</span>
      </td>

      <td [ngClass]="getNasInternColor(klant.backupMacriumUsbOK)" title="{{klant.backupMacriumUsbBericht}}"
        class="boxcolumn">
        <span class="fout" *ngIf="klant.backupMacriumUsbOK == -1" (click)="showDialog(klant, 1022, false)">ERR</span>
        <span class="fout" *ngIf="klant.backupMacriumUsbOK == -20" (click)="showDialog(klant, 1022, true)">MSG</span>
        <span class="fout" *ngIf="klant.backupMacriumUsbOK > 0"
          (click)="showDialog(klant, 1022, false)">{{klant.backupMacriumUsbOK}}</span>
      </td>
    </tr>


  </table>
</div>

<app-remark-dialog #remarkDialog (saved)="getKlantStatusOverview()"></app-remark-dialog>

<div class="container mt-3" *ngIf="hasError">
  <div class="alert alert-danger" role="alert">
    {{errorMessage}}
  </div>
</div>
