import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BackUpOverviewComponent } from './back-up-overview/back-up-overview.component';
import { MonitorComponent } from './monitor/monitor.component';
import { VoipMonitorComponent } from './voip-monitor/voip-monitor.component';

const routes: Routes = [
  { path: 'backups', component: BackUpOverviewComponent },
  { path: 'voip', component: VoipMonitorComponent },
  { path: '**', component: MonitorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
