<div class="backup-header display-4">
  Back-up status
</div>
<div class="backup-header-light">
  <span *ngIf="lastUpdate">Laatst bijgewerkt op {{lastUpdate | date: 'dd-MM-yyyy HH:mm'}}</span>
  <span *ngIf="!lastUpdate">Bijwerken...</span>
</div>
<div class="container mt-3">

  <div class="text-grey mb-2">
    <a class="link-grey" routerLink="/">
      <fa-icon [icon]="homeIcon"></fa-icon>
    </a><b>&nbsp;/ Back-ups</b>
  </div>

  <div class="table-responsive" *ngIf="items && !hasError">
    <table class="table table-sm table-striped table-hover">
      <thead class="thead-marc">
        <tr>
          <th scope="col">&nbsp;</th>
          <th scope="col">Taak</th>
          <th scope="col">Tijdstip</th>
          <th scope="col">Bericht</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items" class="cursor-pointer">
          <td class="align-middle text-center">
            <fa-icon *ngIf="getTaskIcon(item) == 0" [icon]="goodIcon" class="text-success h4"></fa-icon>
            <fa-icon *ngIf="getTaskIcon(item) == 1" [icon]="warningIcon" class="text-warning h4"></fa-icon>
            <fa-icon *ngIf="getTaskIcon(item) == 2" [icon]="errorIcon" class="text-danger h4"></fa-icon>
          </td>
          <td class="align-middle">
            {{item.klantNaam}} ({{item.taakOmschrijving}})
          </td>
          <td class="align-middle">
            {{item.tijdstip | date:"dd-MM-yyyy HH:mm"}}
          </td>
          <td class="align-middle">
            {{getStatusMessage(item)}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="hasError">
    <div class="alert alert-danger" role="alert">
      {{errorMessage}}
    </div>
  </div>
</div>
