import { Component, OnInit } from '@angular/core';
import { BackUpStatus } from '../models/back-up-status';
import { BackendService } from '../backend.service';
import {
  faExclamationTriangle,
  faExclamationCircle,
  faTimes,
  faCheckCircle,
  faHome,
  faStopCircle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { AuthResponse } from '../models/auth-response';

@Component({
  selector: 'app-back-up-overview',
  templateUrl: './back-up-overview.component.html',
  styleUrls: ['./back-up-overview.component.css'],
})
export class BackUpOverviewComponent implements OnInit {
  interval: number = 30000;
  items: BackUpStatus[];
  lastUpdate: Date;
  hasError: boolean = false;
  errorMessage: string;

  warningIcon = faExclamationTriangle;
  errorIcon = faExclamationCircle;
  goodIcon = faCheckCircle;
  homeIcon = faHome;

  constructor(private backendService: BackendService) {}

  ngOnInit(): void {
    this.getBackupOverview();
  }

  getBackupOverview() {
    this.backendService.getBackUpOverview().subscribe(
      (view) => {
        console.log(`Fetched ${view.length} items`);
        console.log(view);
        this.hasError = false;
        this.items = view;
        this.lastUpdate = new Date();

        setTimeout(() => {
          this.getBackupOverview();
        }, this.interval);
      },
      (error) => {
        console.log(error);
        this.hasError = true;
        if (error.status == 401) {
          const authResponse = error.error as AuthResponse;
          this.errorMessage = `Geen toegang (${authResponse.message})`;
        } else {
          this.errorMessage = 'Fout bij het ophalen van de gegevens.';
        }

        setTimeout(() => {
          this.getBackupOverview();
        }, this.interval);
      }
    );
  }

  getTaskIcon(status: BackUpStatus) {
    const daysThreshold = 3;
    if (status.taakResultaat == 0) return 0;
    if (status.taakResultaat > daysThreshold) return 2;
    if (status.taakResultaat > 0) return 1;
    return 2;
  }

  getIconColor(icon: IconDefinition) {
    switch (icon) {
      case this.goodIcon:
        return 'text-success';
      case this.warningIcon:
        return 'text-warning';
      case this.errorIcon:
        return 'text-danger';
      default:
        return '';
    }
  }

  getStatusMessage(status: BackUpStatus) {
    if (status.tijdstipTeOud)
      return 'Taak langer dan 24 uur geleden uitgevoerd!';
    if (status.taakResultaat == 0) return 'OK';
    if (status.taakResultaat == 1) return `USB schijf niet vervangen.`;
    if (status.taakResultaat > 0)
      return `USB schijf al ${status.taakResultaat} dagen niet vervangen.`;
    return 'Er heeft zich een fout voorgedaan.';
  }
}
