import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.css'],
})
export class ClockComponent implements OnInit {
  currentTime: Date = new Date();
  interval: number = 1000;

  constructor() {}

  ngOnInit(): void {
    this.updateTime();
  }

  updateTime() {
    //console.log('Updating time');
    this.currentTime = new Date();
    setTimeout(() => {
      this.updateTime();
    }, this.interval);
  }
}
