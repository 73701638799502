export class KlantStatusInfo {
    klantId: number;
    klantNaam: string;
    windowsUpdatesOK: number;
    windowsUpdatesBericht: string;
    schijfruimteOK: number;
    schijfruimteBericht: string;
    sqlServerAgentOK: number;
    sqlServerAgentBericht: string;
    etVersieOK: number;
    etVersie: string;
    etVersieString: string;
    mailAlerterOK: number;
    mailAlerterBericht: string;
    bandenOK: number;
    bandenBericht: string;
    dashboardOK: number;
    dashboardBericht: string;
    intersprintAanmakenPrijzenOK: number;
    intersprintAanmakenPrijzenBericht: string;
    intersprintVoorradenPrijzenOK: number;
    intersprintVoorradenPrijzenBericht: string;
    intertyreAanmakenPrijzenOK: number;
    intertyreAanmakenPrijzenBericht: string;
    intertyreVoorradenPrijzenOK: number;
    intertyreVoorradenPrijzenBericht: string;
    vanDenBanVolledigOK: number;
    vanDenBanVolledigBericht: string;
    vanDenBanBijwerkenOK: number;
    vanDenBanBijwerkenBericht: string;
    mijngrossierProcesOK: number;
    mijngrossierProcesBericht: string;
    backupNasMacriumOK: number;
    backupNasMacriumBericht: string;
    backupNasInternOK: number;
    backupNasInternBericht: string;
    backupMacriumUsbOK: number;
    backupMacriumUsbBericht: string;
}