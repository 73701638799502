import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MonitorComponent } from './monitor/monitor.component';
import { ClockComponent } from './clock/clock.component';

import { HttpClientModule } from '@angular/common/http';
import { RemarkDialogComponent } from './remark-dialog/remark-dialog.component';
import { FormsModule } from '@angular/forms';
import { BackUpOverviewComponent } from './back-up-overview/back-up-overview.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VoipMonitorComponent } from './voip-monitor/voip-monitor.component';

@NgModule({
  declarations: [
    AppComponent,
    MonitorComponent,
    ClockComponent,
    RemarkDialogComponent,
    BackUpOverviewComponent,
    VoipMonitorComponent
  ],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, FormsModule, FontAwesomeModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
