<div *ngIf="visible" class="dialogParent">
  <div class="dialog">

    <h1>Notitie maken</h1>
    <hr />

    <div class="dialogRow">
      <label>Klant:</label>
      <div>{{klant.klantNaam}}</div>
    </div>

    <div class="dialogRow">
      <label>Taak:</label>
      <div>{{taakOmschrijving}}</div>
    </div>

    <div class="dialogRow">
      <label for="txtNotitie">Notitie:</label>
      <div>
        <input id="txtNotitie" type="text" [(ngModel)]="opmerking" class="dialogInput" autofocus />
      </div>
    </div>

    <div class="dialogRow" ng-if="dialog.aantalDagen > 0">
      <label>Hoelang wil je deze notitie laten staan?</label>
      <div>
        <input type="radio" name="radAantalDagen" id="radAantalDagen1" value="1" [(ngModel)]="aantalDagen"><label
          for="radAantalDagen1">1 dag</label><br />
        <input type="radio" name="radAantalDagen" id="radAantalDagen3" value="3" [(ngModel)]="aantalDagen"><label
          for="radAantalDagen3">3 dagen</label><br />
        <input type="radio" name="radAantalDagen" id="radAantalDagen7" value="7" [(ngModel)]="aantalDagen"><label
          for="radAantalDagen7">7 dagen</label>
      </div>
    </div>

    <div class="buttonbar">
      <button id="btnSave" (click)="saveDialog()">Opslaan</button>
      <button (click)="closeDialog()">Terug</button>
    </div>

    <div class="dialogRow" *ngIf="hasError">
      {{errorMessage}}
    </div>

  </div>
  <!-- <script>
      var txtNotitie = document.getElementById('txtNotitie');
      txtNotitie.focus();
    </script> -->
</div>
